import restfulAPI from "./Base"


export const getNotice = (props) => {
  restfulAPI({
    method: "GET",
    pathname: "notice",
    ...props
  })
}

// export const patchClaim = (props) => {
//   restfulAPI({
//     method: "PATCH",
//     pathname: "claim",
//     contentType: "application/json",
//     ...props
//   })
// }


export const postNotice = (props) => {
  restfulAPI({
    method: "POST",
    pathname: "notice",
    contentType: "application/json",
    ...props
  })
}

export const deleteNotice = (props) => {
  restfulAPI({
    method: "DELETE",
    pathname: "notice",
    contentType: "application/json",
    ...props
  })
}

export const countNotice = (props) => {
  restfulAPI({
    method: "GET",
    pathname: "notice/count",
    ...props
  })
}

export const setReadNotice = (props) => {
  restfulAPI({
    method: "POST",
    pathname: "notice/set_read",
    contentType: "application/json",
    ...props
  })
}