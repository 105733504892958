// import { BACKEND_SERVER_HOSTNAME, BACKEND_SERVER_PROTOCOL, BACKEND_SERVER_PORT} from './config'
import firebase from 'auth/Firebase';
import axios from 'axios';

export const getUrl = (pathname) => {
  return require('url').format({
    protocol: process.env.REACT_APP_BACKEND_SERVER_PROTOCOL,
    hostname: process.env.REACT_APP_BACKEND_SERVER_HOSTNAME,
    port: process.env.REACT_APP_BACKEND_SERVER_PORT,
    // pathname: pathname
  });
}

const restfulAPI = (props) => {
  const { 
    pathname = '', 
    method = 'GET', 
    params = {},
    data = {}, 
    onUploadProgress= null,
    responseType = "json",
    contentType = "multipart/form-data",
    handleResponse = (res) => console.log(res.data), 
    handleError = error => console.log(error.response), 
    handleFirebaseError = error => console.log(error)
  } = props;
  const user = firebase.auth().currentUser;
  const server = getUrl(pathname) + "/" + pathname
  // console.log(data)
  user.getIdToken(false /* Without refreshing every time. */ ) 
  .then(idToken => {
    axios({
      method: method,
      url: server,
      headers: {
        'content-type': contentType,
        'Authorization': idToken,
      },
      params: params,
      data: data,
      responseType: responseType,
      onUploadProgress: onUploadProgress
    })
    .then(handleResponse)
    .catch(handleError)
  }).catch(handleFirebaseError)   
}

export default restfulAPI;