import * as React from "react";
import styled, { withTheme } from "styled-components";
import { connect } from "react-redux";

import { useHistory } from "react-router-dom";
import {
  Grid,
  Hidden,
  AppBar as MuiAppBar,
  Badge,
  IconButton as MuiIconButton,
  Toolbar
} from "@material-ui/core";

import { Menu as MenuIcon, Notifications as NotificationsIcon, NotificationsNone as NotificationsNoneIcon } from "@material-ui/icons";

import{
  countNotice
} from "api/Notice"

const AppBar = styled(MuiAppBar)`
  background: ${props => props.theme.header.background};
  color: ${props => props.theme.header.color};
  box-shadow: ${props => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

type HeaderProps = {
  theme: {};
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
}

// const Header: React.FC<HeaderProps> = ({ onDrawerToggle }) => (

const Header = (props : HeaderProps) => {

  const history = useHistory();
  const [noticeCount, setNoticeCount] = React.useState(0);

  React.useEffect(() => countNotice({
    handleResponse: (res: any) =>{
      const valueJson: any = res.data
      setNoticeCount(valueJson.unread);
      // console.log(res.data)
    }
  }), []);

  return(
  <React.Fragment>
    <AppBar position="sticky" elevation={0}>
      <Toolbar>
        <Grid container alignItems="center">
            <Grid item>
              <Hidden mdUp>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={props.onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Hidden>
            </Grid>
{/*          <Grid item>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <Input placeholder="Search topics" />
            </Search>
          </Grid>*/}
          <Grid item xs />
          <Grid item>
{/*            <IconButton color="inherit">
              <Indicator badgeContent={3}>
                <MessageSquare />
              </Indicator>
            </IconButton>
*/}
            <IconButton color="inherit">
              <Badge badgeContent={noticeCount} color="primary">
                <NotificationsIcon onClick={(()=>history.push({pathname: `/alert`}))} />
              </Badge>
            </IconButton>
{/*            <LanguageMenu />
            <UserMenu />*/}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  </React.Fragment>
);
}

export default connect()(withTheme(Header));
