import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import { Button as MuiButton, Typography } from "@material-ui/core";
import { spacing } from "@material-ui/system";

import { MuiButtonSpacingType } from "../../../types/types";

const Button = styled(MuiButton)<MuiButtonSpacingType>(spacing);

const Wrapper = styled.div`
    padding: ${(props) => props.theme.spacing(6)}px;
    text-align: center;
    background: transparent;

    ${(props) => props.theme.breakpoints.up("md")} {
        padding: ${(props) => props.theme.spacing(10)}px;
    }
`;

function Page404() {
    const { t } = useTranslation();
    return (
        <Wrapper>
            <Helmet title="404 Error" />
            <Typography component="h1" variant="h1" align="center" gutterBottom>
                404
            </Typography>
            <Typography component="h2" variant="h5" align="center" gutterBottom>
                {t("error.404.text")} {/* ページが見つかりません。 */}
            </Typography>
            <Typography
                component="h2"
                variant="body1"
                align="center"
                gutterBottom
            >
                {t("error.url")}{" "}
                {/* URLを間違えているか、ページが削除された可能性があります。 */}
            </Typography>
            <Button
                component={Link}
                to="/"
                variant="contained"
                color="secondary"
                mt={2}
            >
                {t("button.returnToTop")} {/* トップページに戻る。 */}
            </Button>
        </Wrapper>
    );
}

export default Page404;
