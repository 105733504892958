import firebase from 'firebase/app';
import "firebase/firestore";
import "firebase/auth";
// コメントアウト by saeki
//import "firebase/messaging";


require('dotenv').config();
// console.log(process.env.REACT_APP_API_KEY);

export const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
};

firebase.initializeApp(firebaseConfig);

// コメントアウト by saeki
// const messaging = firebase.messaging();




// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     massaging.onMessage(messaging, (payload) => {
//       console.log("payload", payload);
//       resolve(payload);
//     });
//   });

export const db = firebase.firestore();
export default firebase;

