import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector'
import backend from "i18next-http-backend";

import common_en from "./i18n/en/common.json";
import common_jp from "./i18n/jp/common.json";

//---Using different namespaces
const resources = {
    jp: {
        translation: common_jp,
    },
    en: {
        translation: common_en,
    },
};
if (localStorage.getItem('i18nextLng') === null) {
    localStorage.setItem('i18nextLng', 'jp')
}

i18next
    .use(LanguageDetector)
    .use(backend)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: ["jp", "en"],
        supportedLngs: ["jp", "en"],
    });

export default i18next;

//"i18next": "^20.2.1" "react-i18next": "^11.8.13"
