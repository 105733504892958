import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Formik, FormikValues } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { MuiButtonSpacingType } from "../../../types/types";
import { Helmet } from "react-helmet";
import firebase from "../../../auth/Firebase";
import LanguageMenu from "components/LanguageMenu";

import {
    CircularProgress,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    Input,
    InputLabel,
    Button as MuiButton,
    Paper,
    Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

import { RouteComponentProps } from "react-router-dom";

const Button = styled(MuiButton)(spacing);
const LinkButton = styled(MuiButton)<MuiButtonSpacingType>(spacing);

const Wrapper = styled(Paper)`
    padding: ${(props) => props.theme.spacing(6)}px;

    ${(props) => props.theme.breakpoints.up("md")} {
        padding: ${(props) => props.theme.spacing(10)}px;
    }
`;


function SignIn({ history }: RouteComponentProps) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const handleOnSubmit = (values: FormikValues) => {
        //spinner表示開始
        setLoading(true);
        //サインイン（ログイン）処理
        firebase
            .auth()
            .signInWithEmailAndPassword(values.email, values.password)
            .then((res) => {
                //正常終了時
                history.push("/");
                setLoading(false);
            })
            .catch((error) => {
                //異常終了時
                setLoading(false);
                alert(error);
            });
    };

    useEffect(() => {
        return () => {
            // Cleanup function to prevent state updates on unmounted component
            setLoading(false);
        };
    }, []);

    return (
        <Wrapper>
            <Helmet title="Sign In" />
            <Typography
                component="h1"
                variant="h4"
                align="center"
                gutterBottom
            >
                Seegauge Client
            </Typography>

            <Formik
                initialValues={{ email: "", password: "" }}
                onSubmit={(values) => handleOnSubmit(values)}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email().required("must be email"),
                    password: Yup.string().required(),
                })}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    touched,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="email">
                                Email Address
                            </InputLabel>
                            <Input
                                type="email"
                                name="email"
                                id="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="password">
                                Password
                            </InputLabel>
                            <Input
                                name="password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </FormControl>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            mb={2}
                            disabled={loading}
                        >
                            <CircularProgress
                                size="sm"
                                style={{ marginRight: 5 }}
                                hidden={!loading}
                            />
                            {t("button.signIn")} {/* サインイン */}
                        </Button>
                        <LinkButton
                            component={Link}
                            to="/auth/reset-password-before-sign-in"
                            fullWidth
                            color="primary"
                        >
                            {t(
                                "auth.resetPasswordBeforeSignIn.resetPassword"
                            )}{" "}
                            {/* パスワードを忘れましたか? */}
                        </LinkButton>
                    </form>
                )}
            </Formik>
            <Grid container justify="flex-end">
                <Grid item>
                    <LanguageMenu />
                </Grid>
            </Grid>
        </Wrapper>
    );
}

export default SignIn;
