import React from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

import { Button, Menu, MenuItem } from "@material-ui/core";

import LanguageSharpIcon from "@material-ui/icons/LanguageSharp";

export default function LanguageMenu(): JSX.Element {
  const { t, i18n } = useTranslation();
  const [cookies, setCookie] = useCookies(["lang"]);
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);

  function toggleMenu(event: React.SyntheticEvent): void {
    setAnchorMenu(event.currentTarget);
  }

  function closeMenu(): void {
    setAnchorMenu(null);
  }

  function handleClick(lang: string): void {
    closeMenu();
    setCookie("lang", lang, {
      expires: new Date("3021-04-01 23:59:59") /* eternally */
    });
    i18n.changeLanguage(lang);
  }

  return (
    <>
      <Button
        aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        variant="contained"
        color="inherit"
      >
        <LanguageSharpIcon />
        &nbsp;
        {t("sidebar.language")}
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem
          onClick={() => {
            handleClick("jp");
          }}
        >
          日本語
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClick("en");
          }}
        >
          English
        </MenuItem>
      </Menu>
    </>
  );
}
